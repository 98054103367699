<template>
  <div class="default-table">
    <ApeTable ref="apeTable" :data="dataList" :columns="columns" :loading="loadingStatus" :pagingData="pagingData"
              @switchPaging="switchPaging" highlight-current-row>

    </ApeTable>
  </div>
</template>

<script>
import ApeTable from "@/components/ApeTable";

export default {
  name: "LoginRecord",
  components: {
    ApeTable,
  },
  props: {
    userId: {
      type: String,
      default() {
        return ''
      }
    }
  },
  data() {
    return {
      // 搜索条件
      searchCondition: {user_id: null},
      loadingStatus: false,
      columns: [
        {
          title: '用户名',
          value: 'name',
          width: 110
        },
        {
          title: '登录时间',
          value: 'created_at',
          width: 140
        },
        {
          title: '登录IP',
          value: 'real_ip',
          width: 140
        },
      ],
      // 表格列表数据
      dataList: [],
      // 分页信息
      pagingData: {
        is_show: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        offset: 0,
      },
      // 分页的offset,序号列使用
      offset: 0,
    }
  },
  watch: {
    userId(newVal) {
      this.searchCondition.user_id = newVal
    }
  },
  methods: {
    // 切换页码操作
    async switchPaging() {
      await this.initList()
    },
    // 初始化用户列表
    async initList(type) {
      let pagingInfo = this.$refs['apeTable'].getPagingInfo(type)
      let searchCondition = this.searchCondition
      // 合并
      Object.assign(searchCondition, pagingInfo)
      let {list, pages} = await this.$api.getUserLoginRecord(searchCondition)
      this.dataList = list
      this.pagingData.total = pages.total
      this.offset = pages.offset
      this.pagingData.offset = pages.offset
      this.loadingStaus = false
    }
  }
}
</script>

<style scoped>

</style>